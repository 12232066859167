
import { formatDateNormal } from "@/app/infrastructures/misc/Utils";
import { StiSCController } from "@/app/ui/controllers/StiSCController";
import { STIController } from "@/app/ui/controllers/STIController";
import { OptionsClass } from "@/domain/entities/MainApp";
import { NeedToSTISTT } from "@/domain/entities/STI";
import { Vue } from "vue-class-component";
import { AccountController } from "@/app/ui/controllers/AccountController";
import { STIScSTTDueListRequest } from "@/data/payload/api/StiScApiRequest";

export default class NeedToSTISCList extends Vue {
  controller = StiSCController;

  parameters = {
    bookedId: 0,
    bookedType: "",
    search: "",
    booked: "" as any,
  };

  bookedOption = new OptionsClass();

  async beforeMount(): Promise<void> {
    this.parameters.bookedId = this.controller.STISCSTTDueSummaryData.id;
    this.bookedOption = new OptionsClass({
      name: this.controller.STISCSTTDueSummaryData.posName,
      value: this.controller.STISCSTTDueSummaryData.id.toString(),
      code: this.controller.STISCSTTDueSummaryData.type
    });
    if (this.isAccountPOS) {
      this.parameters.booked = new OptionsClass({
        name: AccountController.accountData.account_type_detail.company_name,
        value: AccountController.accountData.account_type_detail.id.toString(),
        code: AccountController.accountData.account_type_detail.type,
      });
    } else {
      await this.controller.getStiScBookedList().then((res) => {
        const bookedData = res.data.find(
          (booked) =>
            booked.bookedId.toString() === this.parameters.bookedId.toString()
        );
        if (bookedData?.bookedId) {
          this.parameters.booked = new OptionsClass({
            name: bookedData.bookedName,
            value: bookedData.bookedId.toString(),
            code: bookedData.bookedType,
          });
        } else {
          if (this.bookedOption.name) {
            this.parameters.booked = this.bookedOption;
          }
        }
      });
      this.fetchNeedStiSCSummary();
      this.fetchSttDueList();
    }

  }

  get STIBookedListOptions() {
    const options = this.controller.bookedListData.data.map((booked) => {
      return new OptionsClass({
        name: booked.bookedName,
        value: booked.bookedId.toString(),
        code: booked.bookedType,
      });
    });
    if (this.bookedOption.name) {
      return [...options, this.bookedOption];
    }
    return options;
  }

  get columns() {
    return [...this.columns1, ...this.columns2, ...this.colunns3];
  }

  get columns1() {
    return [
      {
        name: "No.",
        styleHead: "w-12 text-left",
        render: (_item: NeedToSTISTT, index: number) => {
          return (
            index +
            1 +
            this.controller.sttDueListData.pagination.limit *
              (this.controller.sttDueListData.pagination.page - 1)
          );
        },
      },
      {
        name: "No. STT",
        styleHead: "w-40 text-left",
        render: (item: NeedToSTISTT) => {
          return item.sttNo;
        },
      },
      {
        name: "No. referensi",
        styleHead: "w-40 text-left",
        render: (item: NeedToSTISTT) => {
          return item.refNo || "-";
        },
      },
      {
        name: "Status terakhir",
        styleHead: "w-32 text-left",
        render: (item: NeedToSTISTT) => {
          return `<div class="text-sm text-black-lp-300 px-1 bg-gray-lp-200 rounded">${item.sttLastStatusId}</div>`;
        },
      },
     
    ];
  }

  get columns2() {
    return [
      {
        name: "Jenis pengiriman",
        styleHead: "w-40 text-left",
        render: (item: NeedToSTISTT) => {
          return `<div class="text-sm text-black-lp-300 px-1 bg-gray-lp-200 rounded">${item.sttProductType}</div>`;
        },
      },
      {
        name: "Total koli",
        styleHead: "w-32 text-left",
        render: (item: NeedToSTISTT) => {
          return item.sttTotalPiece;
        },
      },
      {
        name: "Total berat",
        styleHead: "w-32 text-left",
        render: (item: NeedToSTISTT) => {
          return `${item.sttGrossWeight} kg`;
        },
      },
      {
        name: "Kota tujuan",
        styleHead: "w-32 text-left",
        render: (item: NeedToSTISTT) => {
          return `<div class="text-sm text-black-lp-300 px-1 bg-gray-lp-200 rounded">${item.sttDestinationCityId}</div>`;
        },
      }
    ];
  }

  get colunns3() {
    return [
    {
        name: "Tanggal booking",
        styleHead: "w-48 text-left",
        render: (item: NeedToSTISTT) => {
          return `<div class="flex flex-col">
              <div>${formatDateNormal(
                item.sttBookedAt,
                "DD MMMM YYYY, HH:mm"
              )}</div> 
              <div class="text-sm text-gray-lp-600">${item.sttCreatedName ||
                "-"}</div>
            </div>`;
        },
      },
      {
        name: "Nama POS/klien",
        styleHead: "w-48 text-left",
        render: (item: NeedToSTISTT) => {
          return item.sttBookedName;
        },
      },
    ]
  }

  onSearch(val: string) {
    this.parameters.search = val;
    this.controller.setFirstPageSTTDue();
    this.fetchSttDueList();
  }

  get isAccountPOS() {
    return AccountController.accountData.isPosAccount;
  }

  async fetchSttDueList() {
    const payload = new STIScSTTDueListRequest({
      bookedId: this.parameters.booked?.value || "",
      bookedType: this.parameters.booked?.code,
      page: this.controller.sttDueListData.pagination.page,
      limit: this.controller.sttDueListData.pagination.limit,
      search: this.parameters.search,
    });

    await this.controller.getStiSttDueList(payload);
  }

  fetchNeedStiSCSummary() {
    this.controller.getSTISCSTTDueSummary();
  }

  downloadExcel() {
    const payload = new STIScSTTDueListRequest({
      bookedId: this.parameters.booked?.value || "",
      bookedType: this.parameters.booked?.code || "",
      search: this.parameters.search,
    });

    this.controller.downloadSttDue(payload);
  }
}
