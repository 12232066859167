import { ConvertObjectCamelToSnakeCase } from "@/app/infrastructures/misc/Utils";
import { PayloadFinishSessionSTISC, PayloadProcessUpdateSTISC } from "@/domain/entities/StiSc";
import { ChangeStiScRequestInterface } from "../contracts/StiScRequest";
import { QueryParamsEntities } from "@/domain/entities/MainApp";

/* eslint-disable @typescript-eslint/camelcase */
export class GenerateApiRequest implements ChangeStiScRequestInterface {
  private sttNumbers: Array<string>;

  constructor(sttNumbers: Array<string>) {
    this.sttNumbers = sttNumbers;
  }
  public toJSON(): string {
    return JSON.stringify({
      stt_no: this.sttNumbers
    });
  }
}

export class UpdateToSTISCRequest implements ChangeStiScRequestInterface {
  payload: PayloadProcessUpdateSTISC = new PayloadProcessUpdateSTISC();
  
  constructor(fields?: Partial<PayloadProcessUpdateSTISC>) {
    Object.assign(this.payload, fields);
  }
  public toJSON(): string {
    return JSON.stringify(ConvertObjectCamelToSnakeCase(this.payload));
  }
}

export class FinishSessionSTISCRequest implements ChangeStiScRequestInterface {
  payload: PayloadFinishSessionSTISC = new PayloadFinishSessionSTISC();

  constructor(fields?: Partial<PayloadFinishSessionSTISC>) {
    Object.assign(this.payload, fields);
  }
  public toJSON(): string {
    return JSON.stringify(ConvertObjectCamelToSnakeCase(this.payload));
  }
}

export class STISCReverseScanTemporaryListRequest {
  sttNo = "";
  bookedId = "";
  bookedType = "";

  constructor(fields?: Partial<STISCReverseScanTemporaryListRequest>) {
    Object.assign(this, fields);
  }

  toQueryString(): string {
    return new QueryParamsEntities(this).queryString;
  }
}

export class STIScSTTDueListRequest {
  page = 1;
  limit = 20;
  isTotalData = true;
  bookedType = "";
  bookedId = "" as any;
  search = "";

  constructor(fields?: Partial<STIScSTTDueListRequest>) {
    Object.assign(this, fields);
  }

  toQueryString(): string {
    return new QueryParamsEntities(this).queryString;
  }
}

export class CreateSTISCReverseScanRequest {
  sttNo = "";

  constructor(fields?: Partial<CreateSTISCReverseScanRequest>) {
    Object.assign(this, fields);
  }

  toJSON(): string {
    const payload = {
      stt_no: this.sttNo
    };

    return JSON.stringify(payload);
  }
}

export class STISCSTTDueSummaryRequest {
  bookedType = "";
  bookedId = 0;

  constructor(fields?: Partial<STISCSTTDueSummaryRequest>) {
    Object.assign(this, fields);
  }

  toQueryString(): string {
    return new QueryParamsEntities(this).queryString;
  }
}

export class STISCReverseScanManifestRequest {
  bookedType = "";
  bookedId = 0;

  constructor(fields?: Partial<STISCReverseScanManifestRequest>) {
    Object.assign(this, fields);
  }

  toJSON() {
    const payload = {
      booked_id: Number(this.bookedId) as any,
      booked_type: this.bookedType as any
    };

    if (!this.bookedId) {
      delete payload.booked_id;
      delete payload.booked_type;
    }

    return JSON.stringify(payload);
  }
}
